import { notification } from 'antd';
import { navigate } from 'gatsby';
import LocalizedStrings from 'react-localization';
import store from 'store';

import './notification.css';

const GATSBY_STYLE_SHOW_PUBLIC_LOGIN_FORM = process.env.GATSBY_STYLE_SHOW_PUBLIC_LOGIN_FORM!;
const strings = new LocalizedStrings({
    en: {
        tokenExpired: 'Your session has expired. Please login again.',
        success: 'Success!',
        error: 'Error!',
        operatorsNotOnline: 'Booth operators are not online at the moment. Please try again later.',
        pubnubFailed: 'No connection',
        roomReachedMaximum: 'This room slot has reached max participant count. Please try another room.',
    },
    de: {
        tokenExpired: 'Ihre Sitzung ist abgelaufen. Bitte loggen Sie sich erneut ein. ',
        success: 'Erfolgreich!',
        error: 'Hinweis!',
        operatorsNotOnline: 'Der Empfang ist aktuell leider nicht besetzt. Bitte versuchen Sie es später noch einmal',
        pubnubFailed: 'Keine Verbindung',
        roomReachedMaximum:
            'Diese Führung hat die maximale Teilnehmerzahl erreicht. Bitte wählen Sie eine andere Führung.',
    },
});

export const showNotification = (
    type: 'success' | 'info' | 'warning' | 'error',
    title?: string,
    description?: string,
    duration?: number | null,
) => {
    notification[type]({
        duration: duration === null ? null : duration || 4,
        message: title,
        description: description,
    });
};

export const getFormattedErrorMessage = (message?: string) => {
    if (message?.includes('not online')) return strings.operatorsNotOnline;
    else if (message?.includes('room slot has reached max')) return strings.roomReachedMaximum;
    else if (message?.includes('PubNub call failed')) return strings.pubnubFailed;
    return message;
};

export const handleError = (err: any) => {
    if (typeof err === 'object') {
        const typedError = err as APIRequester.APIError & Error;
        const eventAuthModule = store.get('eventAuthModule');
        const eventType = GATSBY_STYLE_SHOW_PUBLIC_LOGIN_FORM
            ? 'semi'
            : eventAuthModule === null
            ? 'public'
            : 'private';

        if (typedError.code === 'REFRESH_TOKEN_EXPIRED' || typedError.code === 'ACCESS_TOKEN_EXPIRED') {
            showNotification('error', 'Error!', strings.tokenExpired);
            store.remove('token');
            store.remove('refreshToken');
            store.remove('user');
            if (eventType === 'private')
                navigate(`/login${typeof window !== 'undefined' ? window.location.search : ''}`);
            else if (eventType === 'semi')
                navigate(`/register${typeof window !== 'undefined' ? window.location.search : ''}`);
            else if (eventType === 'public')
                navigate(`/${typeof window !== 'undefined' ? window.location.search : ''}`);
        } else if (typedError.code === 'INVALID_TOKEN') {
            store.remove('user');
            store.remove('token');
            store.remove('refreshToken');
            navigate(`/login${typeof window !== 'undefined' ? window.location.search : ''}`);
        } else
            showNotification(
                'error',
                strings.error,
                getFormattedErrorMessage(
                    typedError.extra || typedError.description || typedError.message || typedError.code,
                ),
            );
    } else if (err.message) showNotification('error', strings.error, err.message);
    console.error(err);
};

export const handleSuccess = (message: string) => {
    showNotification('success', strings.success, message);
};
